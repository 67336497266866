import React, { Component } from "react";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import {
  HeaderFontCss,
  ParagraphFontCss,
  TitleFontCssByEN,
} from "../Utils/CssStyleTemplateUtil";
import { s, d } from "../Utils/Lang";
import * as Widget from "../Components/Widget";
import ProjectCarousel from "../Components/ProjectCarousel";
import CoreValueSection from "../Components/CoreValueSection";
import OurPartnersSection from "../Components/OurPartnersSection";

const PROJECT_DESKTOP_ITEMS = [
  { img: "slide-desktop-01.jpg", home: true },
  { img: "hiprint-desktop.webp" },
  { img: "sens-desktop.webp" },
  {
    img: "slide-desktop-02.jpg",
  },
  {
    img: "slide-desktop-03.jpg",
  },
  {
    img: "slide-desktop-04.jpg",
  },
];

const PROJECT_MOBILE_ITEMS = [
  { img: "slide-mobile-01.jpg", home: true },
  { img: "hiprint-mobile.webp" },
  { img: "sens-mobile.webp" },
  {
    img: "slide-mobile-02.jpg",
  },
  {
    img: "slide-mobile-03.jpg",
  },
  {
    img: "slide-mobile-04.jpg",
  },
];

const CORE_VALUE_ITEMS = [
  {
    icon: "icon-revoluationary.png",
    highlightText: "Rev",
    text: "olutionary",
    name: "revolutionary",
  },
  {
    icon: "icon-revenue.png",
    highlightText: "Rev",
    text: "enue",
    name: "revenue",
  },
  {
    icon: "icon-revive.png",
    highlightText: "Rev",
    text: "ive",
    name: "revive",
  },
];
const OUR_PARTNERS_ITEMS = [
  { icon: "ITRI.webp", alt: "工研院" },
  { icon: "III.png", alt: "資策會" },
  { icon: "tcb.png", alt: "合作金庫證券" },
  { icon: "twba.png", alt: "全國律師聯合會" },
  { icon: "tba.png", alt: "台北市律師公會" },
  { icon: "twpaa.png", alt: "全國專利師公會" },
  { icon: "tda.png", alt: "台北牙醫師公會" },
  { icon: "att.png", alt: "ATT 影城" },
  { icon: "nb.png", alt: "New Balance" },
  { icon: "OneDegree.png", alt: "OneDegree" },
  { icon: "dsa.png", alt: "達詳自動化" },
  { icon: "fuji.png", alt: "台灣富士資訊" },
  { icon: "Merck.webp", alt: "台灣默克" },
  { icon: "Sensation.webp", alt: "感官文化印刷" },
  { icon: "inigma-2.png", alt: "加密鏈冷錢包" },

  { icon: "moea.png", alt: "經濟部商業司" },
  { icon: "tca.png", alt: "台北市電腦公會" },
  { icon: "ideashatch.png", alt: "ideasHatch" },
  { icon: "gamma.png", alt: "高曼計量財務" },
  { icon: "laya.png", alt: "拉亞漢堡" },
  { icon: "teco.png", alt: "東元捷德" },
  { icon: "redreamer.png", alt: "ReDreamer" },
  { icon: "lr.png", alt: "法朋烘焙坊" },
  { icon: "pranaq-.png", alt: "Pranaq" },
  { icon: "greenjump.png", alt: "易境永續" },
  { icon: "bdff.png", alt: "直接跟農夫買" },
  { icon: "culture.png", alt: "文化部" },

  { icon: "uber.png", alt: "Uber" },
  //{ icon: "taipei.png", alt: "台北共融" },
  { icon: "formosa-bio-2.png", alt: "台塑生醫" },
  { icon: "adenovo.png", alt: "ADENOVO 諦諾智金" },
  //{ icon: "bakey.png", alt: "BakerShell" },
  { icon: "sj.png", alt: "三久建材" },
  { icon: "ux.png", alt: "悠識數位" },
  { icon: "ikala-p.png", alt: "iKala" },

  { icon: "headwaterven.png", alt: "水源資本" },
  { icon: "jpass-logo-min-removebg-preview-min.png", alt: "Jpass" },
  //{ icon: "Tel25.png", alt: "二十五電訊" },

  // { icon: "yutung.png" },
];

class LandingPage extends Component {
  state = {
    windowWidth: 0,
  };

  componentDidMount() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  render() {
    return (
      <Wrapper>
        {this._rowLandingPage()}
        {this._colLandingPage()}
      </Wrapper>
    );
  }

  _rowLandingPage = () => {
    return (
      <RowContainer>
        <div style={{ display: "flex", width: "100%" }}>
          <div
            className="introduction-section"
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              backgroundImage: 'url("/images/slide-desktop-01.jpg")',
              height: "100vh",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.1)",
                borderRadius: 10,
                padding: 50,
              }}
            >
              <h1 className="text-linear-gradient" style={{ color: "#F89746" }}>
                {s("landing-title")}
              </h1>
              <a
                className="description"
                style={{
                  marginBottom: 30,
                  color: "black",
                  textDecoration: "none",
                }}
                href="/service"
              >
                <h2 style={{ fontSize: 16 }}>{s("landing-introduction")}</h2>
              </a>

              <p style={{ textAlign: "center", color: "#eeeeee" }}>
                <h3 style={{ fontWeight: 300 }}>{s("landing-description")}</h3>
              </p>
            </div>
          </div>
        </div>

        <div className="home-section" style={{ paddingTop: 80 }}>
          <div className="slogan-container">
            <h2 style={{ marginBottom: 50 }}>
              {s("landing-core-value_title")}
            </h2>
            <CoreValueSection items={CORE_VALUE_ITEMS} />
          </div>
          <div className="partners-container">
            <h2 style={{ marginBottom: 30 }}>
              {s("landing-our-partners_title")}
            </h2>
            <p style={{ textAlign: "center", marginBottom: 83 }}>
              {s("landing-our-partners_introduction")}
            </p>
            <OurPartnersSection items={OUR_PARTNERS_ITEMS} />
          </div>
        </div>
      </RowContainer>
    );
  };

  _colLandingPage = () => {
    return (
      <ColContainer>
        <div
          className="grade-container"
          style={{
            backgroundImage: 'url("/images/slide-desktop-01.jpg")',
            height: "50vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0,0,0,0.1)",
              borderRadius: 20,
              padding: 20,
            }}
          >
            <h1
              className="text-linear-gradient"
              style={{ marginBottom: 10, color: "#F89746", fontSize: 28 }}
            >
              {s("landing-title")}
            </h1>
            <a
              href="/industry"
              style={{
                textDecoration: "none",
                marginTop: 10,
                color: "lightgrey",
              }}
            >
              <h2 style={{ fontSize: 16 }}>{s("landing-introduction")}</h2>
            </a>
            <p
              className="description"
              style={{
                maxWidth: "100%",
                marginTop: 20,
                textAlign: "center",
                color: "white",
              }}
            >
              <h3 style={{ fontWeight: 300, fontSize: 12 }}>
                {s("landing-description")}
              </h3>
            </p>
          </div>
        </div>

        <div className="slogan-container">
          <h2 style={{ marginBottom: 50 }}>{s("landing-core-value_title")}</h2>
          <CoreValueSection items={CORE_VALUE_ITEMS} />
        </div>
        <div className="partners-container">
          <h2 style={{ marginBottom: 30 }}>
            {s("landing-our-partners_title")}
          </h2>
          <p style={{ marginBottom: 30, textAlign: "center" }}>
            {s("landing-our-partners_introduction")}
          </p>
          <OurPartnersSection items={OUR_PARTNERS_ITEMS} />
        </div>
      </ColContainer>
    );
  };
}

const RowContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    display: none;
  }

  .introduction-section {
    background-color: #f1f1f1;
    padding: 30px;
    color: #4e4e4e;
    display: flex;
    flex-direction: column;
    flex-basis: 360px;
    flex-grow: 0;
    flex-shrink: 0;

    & h2.text-linear-gradient {
      text-align: left;
      font-style: italic;
      font-size: 30px;
      color: lightgrey;
      letter-spacing: 0.58px;
      line-height: 32px;
      ${TitleFontCssByEN}
      /* reference link: https://css-tricks.com/snippets/css/gradient-text/ */
      /* background-image: linear-gradient(244deg, #55c2e3 41%, #5ae6c2 93%); */
      -webkit-background-clip: text;
    }

    & p.formula {
      font-weight: 500;
      font-size: 25px;
      color: #4e4e4e;
      white-space: pre-wrap;
      letter-spacing: 0.5px;
      line-height: 30px;
      ${HeaderFontCss}
      margin-bottom: 0px;
    }

    & p.answer {
      font-weight: 500;
      font-size: 25px;
      white-space: pre;
      text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.19);
      line-height: 30px;
      margin-bottom: 30px;
      ${HeaderFontCss}
      /* reference link: https://css-tricks.com/snippets/css/gradient-text/ */
      background-image: linear-gradient(22deg, #faa34e 18%, #f36a26 91%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    & p.data {
      min-width: 88px;
      margin-right: 46px;
      line-height: 35px;
      font-size: 25px;
      font-weight: 500;
      text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.22);
      ${HeaderFontCss}
      /* reference link: https://css-tricks.com/snippets/css/gradient-text/ */
      background-image: linear-gradient(22deg, #faa34e 18%, #f36a26 91%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-color: red;
    }

    & p.target {
      min-width: 150px;
      font-size: 25px;
      font-weight: 500;
      color: #4e4e4e;
      letter-spacing: 0.5px;
      line-height: 35px;
      ${HeaderFontCss}
    }
  }

  .home-section {
    background-color: #f1f1f1;
    flex-grow: 1;
    color: #4e4e4e;

    .project-container {
    }

    .slogan-container {
      max-width: 782px;
      width: 100%;
      margin: 0 auto 40px;
    }

    .partners-container {
      max-width: 782px;
      width: 100%;
      margin: 0 auto 40px;
    }
  }

  & h2 {
    text-align: center;
    font-size: 35px;
    color: #4e4e4e;
    letter-spacing: 0.95px;
    line-height: 40px;
    ${HeaderFontCss}
    margin-bottom: 20px;
  }

  & p {
    max-width: 500px;
    //width: 100%;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
    color: #4e4e4e;
    letter-spacing: 0.4px;
    line-height: 20px;
    white-space: pre-wrap;
    ${ParagraphFontCss}
    margin-bottom: 10px;
  }
`;

const Wrapper = styled.div``;

const ColContainer = styled.div`
  background-color: #f1f1f1;

  @media screen and (min-width: ${900 + 1}px) {
    display: none;
  }

  .grade-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    margin-bottom: 5px;

    & h2.text-linear-gradient {
      text-align: left;
      font-style: italic;
      font-weight: 800;
      font-size: 30px;
      color: lightgrey;
      letter-spacing: 0.58px;
      white-space: pre-wrap;
      line-height: 32px;
      ${HeaderFontCss}
      /* reference link: https://css-tricks.com/snippets/css/gradient-text/ */
      -webkit-background-clip: text;
    }

    & p.formula {
      text-align: center;
      font-weight: 500;
      font-size: 25px;
      color: #4e4e4e;
      white-space: pre-wrap;
      letter-spacing: 0.5px;
      line-height: 30px;
      ${HeaderFontCss}
    }

    & p.answer {
      text-align: center;
      font-weight: 500;
      font-size: 25px;
      white-space: pre;
      text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.19);
      line-height: 30px;
      ${HeaderFontCss}
      /* reference link: https://css-tricks.com/snippets/css/gradient-text/ */
      background-image: linear-gradient(22deg, #faa34e 18%, #f36a26 91%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    & p.data {
      min-width: 88px;
      margin-right: 46px;
      line-height: 35px;
      font-size: 25px;
      font-weight: 500;
      text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.22);
      ${HeaderFontCss}
      /* reference link: https://css-tricks.com/snippets/css/gradient-text/ */
      background-image: linear-gradient(22deg, #faa34e 18%, #f36a26 91%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    & p.target {
      min-width: 150px;
      font-size: 25px;
      font-weight: 500;
      color: #4e4e4e;
      letter-spacing: 0.5px;
      line-height: 35px;
      ${HeaderFontCss}
    }
  }

  .project-container {
  }

  .slogan-container {
    padding: 40px 25px 35px;
  }

  .partners-container {
    padding: 35px 55px;
  }

  .contact-container {
    padding: 35px 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & h2 {
    text-align: center;
    font-size: 35px;
    color: #4e4e4e;
    letter-spacing: 0.95px;
    line-height: 40px;
    ${HeaderFontCss}
    margin-bottom: 20px;
  }

  & p {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    font-size: 14px;
    color: #4e4e4e;
    letter-spacing: 0.4px;
    line-height: 20px;
    white-space: pre-wrap;
    ${ParagraphFontCss}
    margin-bottom: 15px;
  }

  @media screen and (max-width: 500px) {
    .logo-container {
      display: none;
    }

    .grade-container {
      padding: 52px 15px 20px;
      margin-bottom: 20px;

      & h2.text-linear-gradient {
      }

      & p.formula {
        text-align: center;
        margin-right: 0px;
      }

      & p.answer {
        text-align: center;
      }
    }

    .project-container {
      margin-bottom: 36px;
    }

    .slogan-container {
      padding: 35px 15px;
    }

    .partners-container {
      padding: 35px 15px;
    }

    .contact-container {
      padding: 15px 15px 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    & h2 {
      font-size: 25px;
    }

    & p {
    }
  }
`;

export default withPage(LandingPage);
