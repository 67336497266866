import React, { Component } from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import { StaticImage } from "./StaticImage";
import * as Icon from "./Icon";
import * as Widget from "./Widget";

class ProjectCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: false,
      slideIndex: 0,
      slideHeight: props.slideHeight || 800,
      slideWidth: props.slideWidth || undefined,
      loading: true,
    };
  }

  componentDidMount() {
    this._checkScreenWidth = setInterval(() => {
      if (window.screen.width <= 1024 && !this.state.mobile) {
        this.setState({ mobile: true, loading: false });
      }

      if (window.screen.width > 1024 && this.state.mobile) {
        this.setState({ mobile: false, loading: false });
      }
    }, 2000);

    this._checkSlideHeight = setInterval(() => {
      if (
        window.screen.width > 1024 &&
        (this.state.slideHeight !== this.state.slideHeight) !== 800
      ) {
        this.setState({ slideHeight: 800, loading: false });
      }

      if (window.screen.width <= 600 && this.state.slideHeight !== 600) {
        this.setState({ slideHeight: 600, loading: false });
      }
    }, 2000);

    if (!this.state.loading) {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    clearInterval(this._checkScreenWidth);
    clearInterval(this._checkSlideHeight);
  }

  render() {
    let { items, mobileItems } = this.props;
    let { loading, mobile, slideHeight, slideWidth } = this.state;

    if (loading) {
      return (
        <Wrapper
          css={`
            flex: 1;
            height: ${slideHeight}px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          {!mobile && <Widget.Spinner />}
        </Wrapper>
      );
    }

    if (mobile) {
      return (
        <Wrapper height={slideHeight}>
          <Carousel
            initialSlideHeight={slideHeight}
            slideIndex={this.state.slideIndex}
            afterSlide={slideIndex => this.setState({ slideIndex })}
            renderCenterLeftControls={() => false}
            renderCenterRightControls={() => false}
            renderBottomCenterControls={() => false}
            style={{ outline: "none" }}
            autoplay={true}
          >
            {mobileItems.map((item, i) => {
              if (item.home) {
                return (
                  <div key={"project-mobile-slide-item-home" + i}>
                    <StaticImage name={item.img} />
                    <div className="slogan">
                      <div className="subtitle">Create Everything</div>
                      <div className="title">始自專注，衷於優質</div>
                      <div className="description">
                        {
                          "量身打造，無所遺漏。\nRevtel Tech 忻旅科技致力於提供最高品質的軟體服務。"
                        }
                      </div>
                    </div>
                  </div>
                );
              }

              return (
                <div key={"project-mobile-slide-item" + i}>
                  <StaticImage name={item.img} />
                  <div className="project">
                    <div className="name">{item.name}</div>
                    <div className="credit">{item.credit}</div>
                  </div>
                  <BackDrop />
                </div>
              );
            })}
          </Carousel>
          {this._renderArrowIcon()}
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Carousel
          initialSlideHeight={slideHeight}
          slideIndex={this.state.slideIndex}
          afterSlide={slideIndex => this.setState({ slideIndex })}
          renderCenterLeftControls={() => false}
          renderCenterRightControls={() => false}
          renderBottomCenterControls={() => false}
          style={{ outline: "none" }}
          autoplay={true}
        >
          {items.map((item, i) => {
            if (item.home) {
              return (
                <div className="slide" key={"project-slide-item-home" + i}>
                  <StaticImage name={item.img} />
                  <div className="slogan">
                    <div className="subtitle">Create Everything</div>
                    <div className="title">始自專注，衷於優質</div>
                    <div className="description">
                      {
                        "量身打造，無所遺漏。\nRevtel Tech 忻旅科技致力於提供最高品質的軟體服務。"
                      }
                    </div>
                  </div>
                </div>
              );
            }

            return (
              <div className="slide" key={"project-slide-item" + i}>
                <StaticImage name={item.img} />
                <div className="project">
                  <div className="name">{item.name}</div>
                  <div className="credit">{item.credit}</div>
                </div>
                <BackDrop height={slideHeight} />
              </div>
            );
          })}
        </Carousel>
        {this._renderArrowIcon()}
      </Wrapper>
    );
  }

  _renderArrowIcon = () => {
    let { slideIndex } = this.state;

    return (
      <>
        <IconButton
          left
          onClick={() => this.setState({ slideIndex: slideIndex - 1 })}
        >
          <Icon.KeyboardArrowLeft color="white" size={26} />
        </IconButton>

        <IconButton
          onClick={() => this.setState({ slideIndex: slideIndex + 1 })}
        >
          <Icon.KeyboardArrowRight color="white" size={26} />
        </IconButton>
      </>
    );
  };
}

const BackDrop = styled.div`
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0px;
  left: 0px;
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  & .slide-backdrop {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    max-height: ${props => props.height}px;
    height: 100%;
  }

  & .slide {
    position: relative;
    width: 100%;
    max-height: ${props => props.height}px;
    height: 100%;
  }

  & .slogan {
    position: absolute;
    top: 135px;
    left: 60px;
    padding: 5px;

    @media screen and (max-width: 1024px) {
      top: 100px;
    }

    & > .subtitle {
      letter-spacing: 0.6px;
      font-size: 30px;
      color: #ffffff;
      margin-bottom: 30px;
    }

    & > .title {
      letter-spacing: 0.8px;
      font-size: 40px;
      color: #ffffff;
      margin-bottom: 50px;
    }

    & .description {
      letter-spacing: 0.4px;
      line-height: 25px;
      white-space: pre-wrap;
      font-size: 14px;
      color: #ffffff;
    }

    @media screen and (max-width: 500px) {
      top: 98px;
      left: 15px;

      & > .subtitle {
        font-size: 20px;
      }

      & > .title {
        font-size: 30px;
      }
    }

    ${props => props.css || ""};
  }

  & .project {
    position: absolute;
    right: 60px;
    bottom: 45px;
    z-index: 100;

    & > .name {
      white-space: pre-wrap;
      font-style: italic;
      font-size: 14px;
      color: #ffffff;
      margin-bottom: 10px;
    }

    & > .credit {
      font-style: italic;
      font-size: 14px;
      color: #ffffff;
    }
  }
`;

const IconButton = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-image: linear-gradient(22deg, #faa34e 18%, #f36a26 91%);

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  ${props => (props.left ? "left: 0px" : "right: 0px")};
`;

export default ProjectCarousel;
