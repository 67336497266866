import React, { Component } from "react";
import styled from "styled-components";
import { HeaderFontCss, ParagraphFontCss } from "../Utils/CssStyleTemplateUtil";
import { s, d } from "../Utils/Lang";
import { StaticImage } from "./StaticImage";
class CoreValueSection extends Component {
  render() {
    let { items } = this.props;

    return (
      <Wrapper>
        {items.map((item, i) => (
          <Item key={"item" + i}>
            <div className="icon-container">
              <StaticImage path={"/landing/"} name={item.icon} />
            </div>
            <div className="title-container">
              <span className="hightlight-text">{item.highlightText}</span>
              <span className="text">{item.text}</span>
            </div>
            <p>{s(`landing-core-value_${item.name}_description`)}</p>
          </Item>
        ))}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const Item = styled.div`
  max-width: 185px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;

  & .icon-container {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }

  & .title-container {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.4px;
    ${HeaderFontCss}
    margin-bottom: 20px;

    & span.hightlight-text {
      color: #f36a26;
    }

    & span.text {
    }
  }

  & p {
    width: 100%;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-align: center;
    font-size: 14px;
    color: #4e4e4e;
    ${ParagraphFontCss}
  }

  @media screen and (max-width: 500px) {
    max-width: 210px;
    & .icon-container {
    }

    & .title-container {
    }

    & p {
    }
  }
`;

export default CoreValueSection;
